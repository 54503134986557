import { BaseEntity, Default, MemoizeGetters, Transform, toDate, toEntity } from './base';
import { ServiceAttributes, ServiceMap, ServiceName, ServiceType } from './service-type';

import { Vehicle } from './vehicle';

@MemoizeGetters
export class Service extends BaseEntity implements ServiceAttributes {
  name: string;
  description?: string;
  slotScheduling: boolean;
  stopDuration: number;

  @Transform(parseFloat)
  price: number;

  @Transform(parseFloat)
  perGallonDiscount?: number;
  title: string;
  provider: boolean;

  @Transform(toEntity(ServiceType))
  serviceType: ServiceType;

  @Default([])
  shouldScheduleWith: ServiceName[];

  @Transform(toDate)
  featuredAt: Date;

  ownerRequired: boolean;
  virtual: boolean;

  canAccept(service: Service) {
    return service.shouldScheduleWith?.includes(this.serviceType.name);
  }

  get featured() {
    return Boolean(this.featuredAt);
  }

  get priority() {
    return (
      (this.json.priority as number) || PRIORITY_MAP[this.serviceType.name] || DEFAULT_PRIORITY
    );
  }

  get frequencyOptions(): number[] {
    return (this.json.frequencyOptions as number[]) || FREQUENCIES_MAP[this.serviceType.name] || [];
  }

  get defaultFrequency(): number {
    return (
      (this.json.defaultFrequency as number) ||
      DEFAULT_FREQUENCY_MAP[this.serviceType.name] ||
      DEFAULT_FREQUENCY
    );
  }

  get shortDescription(): string {
    return (this.json.shortDescription as string) || this.serviceType.shortDescription;
  }

  get imageUrl(): string {
    return this.useProdBucket(this.json.imageUrl as string) || this.serviceType.imageUrl;
  }

  get thumbnailUrl(): string {
    return this.useProdBucket(this.json.thumbnailUrl as string) || this.serviceType.thumbnailUrl;
  }

  private useProdBucket(url: string) {
    return url ? url.replace('.beta', '').replace('.staging', '') : null;
  }

  get gasVehicleOnly(): boolean {
    return this.serviceType.gasVehicleOnly;
  }

  get electricVehicleOnly(): boolean {
    return this.serviceType.electricVehicleOnly;
  }

  get isGas(): boolean {
    return this.serviceType.name === ServiceName.Gas;
  }

  matchesSearch(keywords: string[]): boolean {
    const fullTitle = `${this.name} ${this.title}`.toLowerCase();
    return keywords.every((word) => fullTitle.includes(word.toLowerCase()));
  }

  forVehicle(vehicle: Vehicle) {
    return (
      !(this.gasVehicleOnly && !vehicle.useGas) &&
      !(this.electricVehicleOnly && !vehicle.useElectric)
    );
  }
}

export interface ServiceQueryParams {
  includeFromGlobalRegion?: boolean;
}

const FREQUENCIES_MAP: ServiceMap<number[]> = {
  [ServiceName.Tires]: [7, 14, 28, 56],
  [ServiceName.Wash]: [7, 14, 28, 56],
  [ServiceName.RainX]: [14, 28, 56],
  [ServiceName.OilChange]: [84, 175],
  [ServiceName.WiperBlades]: [84, 175, 266],
  [ServiceName.FuelAdditive]: [28, 56, 84],
  [ServiceName.WiperFluid]: [84, 175, 266],
  [ServiceName.Detail]: [28, 56, 84, 175, 364],
  [ServiceName.RepairShop]: [],
  [ServiceName.Checkup]: [],
  [ServiceName.Health]: [],
};

const DEFAULT_FREQUENCIES = [28, 54, 84];

const DEFAULT_FREQUENCY_MAP: ServiceMap<number> = {
  [ServiceName.Tires]: 28,
  [ServiceName.Wash]: 28,
  [ServiceName.RainX]: 28,
  [ServiceName.OilChange]: 84,
  [ServiceName.WiperBlades]: 175,
  [ServiceName.FuelAdditive]: 28,
  [ServiceName.WiperFluid]: 175,
  [ServiceName.Detail]: 175,
  [ServiceName.RepairShop]: 0,
  [ServiceName.Checkup]: 0,
  [ServiceName.Health]: 0,
};

const DEFAULT_FREQUENCY = 0;

const PRIORITY_MAP: ServiceMap<number> = {
  [ServiceName.Gas]: 1,
  [ServiceName.Tires]: 2,
  [ServiceName.Wash]: 3,
  [ServiceName.RainX]: 4,
  [ServiceName.OilChange]: 5,
  [ServiceName.WiperBlades]: 6,
  [ServiceName.FuelAdditive]: 7,
  [ServiceName.WiperFluid]: 8,
  [ServiceName.Detail]: 9,
  [ServiceName.Polish]: 10,
  [ServiceName.RepairShop]: 11,
  [ServiceName.Checkup]: 12,
  [ServiceName.Health]: 2,
};

const DEFAULT_PRIORITY = 100;
