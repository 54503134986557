import { AddressParams, UserAddress } from 'src/app/models/user-address';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Flow, FlowDirector, FlowPage, FlowPageOptions } from '../../flow-director';
import { Observable, forkJoin } from 'rxjs';
import { Vehicle, VehicleParams } from 'src/app/models/vehicle';
import { finalize, map } from 'rxjs/operators';

import { ActivatedRoute } from '@angular/router';
import { Address } from 'src/app/models/address';
import { AddressConfirmationPage } from '../address-confirmation/address-confirmation.page';
import { AddressService } from 'src/app/services/api/address/address.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { BackButtonService } from 'src/app/services/back-button/back-button.service';
import { IonNav } from '@ionic/angular';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { LoginFlowPage } from '../../login-flow/login-flow.page';
import { ModalService } from 'src/app/services/modal/modal.service';
import { OrderFlowControllerPage } from '../../ordering/order-flow-controller/order-flow-controller.page';
import { OrderScheduleService } from 'src/app/services/scheduling/order-schedule/order-schedule.service';
import { OrderService } from 'src/app/services/api/order/order.service';
import { Service } from 'src/app/models/service';
import { ServiceDetailPage } from 'src/app/pages/services/service-detail/service-detail.page';
import { ServiceGroup } from 'src/app/models/service-group';
import { SimpleMessageModalComponent } from 'src/app/components/simple-message-modal/simple-message-modal';
import { User } from 'src/app/models/user';
import { UserLocationPage } from '../../onboarding/user-location/user-location.page';
import { UserService } from 'src/app/services/api/user/user.service';
import { VehicleConfirmationPage } from '../vehicle-confirmation/vehicle-confirmation.page';
import { VehicleFlowController } from '../../vehicle/vehicle-flow-controller/vehicle-flow-controller.page';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';
import { WebflowEndPage } from '../webflow-end/webflow-end.page';
import { WebflowLoginPage } from '../webflow-login/webflow-login.page';
import { WebflowVariant } from '../webflow-start/webflow-start.variants';

export interface WebflowLoginFlowControllerPageProps {
  variant: WebflowVariant;
  phone?: Nullable<string>;
  service?: Nullable<Service>;
  serviceGroup?: Nullable<ServiceGroup>;
  vehicleInfo?: VehicleParams;
}

interface WebflowLoginFlowControllerPageForm {
  phone?: Nullable<string>;
  user?: Nullable<User>;
  vehicle?: Nullable<Vehicle>;
  address?: Nullable<Address>;
  serviceGroup?: Nullable<ServiceGroup>;
}

@Component({
  selector: 'ysh-webflow-login-flow-controller',
  template: '<ion-nav #webflowLoginNav></ion-nav>',
})
export class WebflowLoginFlowControllerPage implements Flow, OnInit, AfterViewInit {
  @ViewChild('webflowLoginNav') nav: IonNav;

  @Input() props: WebflowLoginFlowControllerPageProps;
  form: WebflowLoginFlowControllerPageForm = {};
  flowDirector: FlowDirector;
  vehicles: Vehicle[] = [];
  addresses: UserAddress[] = [];
  userHasOrder = false;

  onDismiss: () => void;

  constructor(
    public route: ActivatedRoute,
    private analytics: AnalyticsService,
    private userService: UserService,
    private addressService: AddressService,
    private loadingAlerts: LoadingAlertService,
    private modalService: ModalService,
    private vehicleService: VehiclesService,
    private orderService: OrderService,
    private orderSchedule: OrderScheduleService,
    public backButtonService: BackButtonService
  ) {}

  // life cycle hooks
  ngOnInit() {
    this.analytics.trackView('WebflowLoginFlowControllerPage');
  }

  ngAfterViewInit(): void {
    this.form.serviceGroup = this.props.serviceGroup;
    this.form.phone = this.props.phone;
    this.flowDirector = new FlowDirector(this);
  }

  // flow
  nextPage(): Nullable<FlowPageOptions<FlowPage>> {
    if (this.userHasOrder) {
      return this.optionsForEndPage();
    }
    if (!this.form.phone) {
      return this.optionsForLoginPage();
    }
    if (!this.form.user) {
      if (this.props.phone) {
        this.sendPin(this.props.phone);
      }
      return this.optionsForPinPage();
    }
    if (!this.addresses?.length) {
      return this.optionsForUserLocationPage();
    }
    if (!this.form.address) {
      return this.optionsForAddressesPage();
    }
    if (!this.vehicles?.length) {
      return this.optionsForVehicleFlow();
    }
    if (!this.form.vehicle) {
      return this.optionsForVehiclesPage();
    }
    return this.optionsForOrderFlow();
  }

  flowDidComplete(): void {
    this.flowDirector.setRoot(this.optionsForEndPage());
  }

  // params

  optionsForLoginPage(): FlowPageOptions<WebflowLoginPage> {
    return {
      page: WebflowLoginPage,
      onComplete: (phone) => this.loginPageDidComplete(phone),
      onDismiss: () => this.onDismiss?.(),
    };
  }

  optionsForPinPage(): FlowPageOptions<LoginFlowPage> {
    return {
      page: LoginFlowPage,
      onComplete: (user) => this.pinPageDidComplete(user),
      onDismiss: () => (this.form.user = null),
      pageProps: {
        phone: this.form.phone,
        outlineInputStyle: true,
        hideBackButton: this.form.phone ? true : false,
      },
    };
  }

  optionsForUserLocationPage(): FlowPageOptions<UserLocationPage> {
    return {
      page: UserLocationPage,
      onComplete: (params) => this.userLocationPageDidComplete(params),
      onDismiss: () => (this.form.address = null),
      pageProps: {
        outlineInputStyle: true,
      },
    };
  }

  optionsForAddressesPage(): FlowPageOptions<AddressConfirmationPage> {
    return {
      page: AddressConfirmationPage,
      onComplete: (address) => this.addressesPageDidComplete(address),
      onDismiss: () => (this.form.address = null),
      pageProps: {
        addresses: this.addresses,
        selectedAddress: this.addressService.selectedAddress$.value!,
      },
    };
  }

  optionsForVehicleFlow(): FlowPageOptions<VehicleFlowController> {
    return {
      page: VehicleFlowController,
      onComplete: (data) => this.vehicleFlowDidComplete(data),
      props: {
        promptForConnectedCar: false,
        promptForFuel: false,
        promptForLicense: false,
        promptForNickname: false,
      },
    };
  }

  optionsForVehiclesPage(): FlowPageOptions<VehicleConfirmationPage> {
    return {
      page: VehicleConfirmationPage,
      onComplete: (vehicle) => this.vehiclesPageDidComplete(vehicle),
      onDismiss: () => (this.form.vehicle = null),
      pageProps: {
        vehicles: this.vehicles,
        selectedVehicle: this.vehicleService.selectedVehicle$.value!,
      },
    };
  }

  optionsForOrderFlow(): FlowPageOptions<OrderFlowControllerPage> {
    const userAddress = this.addressService.selectedAddress$.value;
    return {
      page: OrderFlowControllerPage,
      onComplete: () => this.orderFlowDidComplete(),
      props: {
        serviceGroup: this.form.serviceGroup!,
        service: this.props.service,
        vehicle: this.form.vehicle!,
        userAddress: userAddress!,
        skipCompletionModal: true,
        skipCompletionToast: true,
        outlineInputStyle: true,
        webflowVariant: this.props.variant,
      },
    };
  }

  optionsforServiceDetailPage(): FlowPageOptions<ServiceDetailPage> {
    return {
      page: ServiceDetailPage,
      onComplete: () => {},
      pageProps: {
        serviceGroup: this.form.serviceGroup!,
        hideFuelDiscount: true,
      },
    };
  }

  optionsForEndPage(): FlowPageOptions<WebflowEndPage> {
    return {
      page: WebflowEndPage,
      onComplete: () => {
        this.userHasOrder = false;
        this.form.address = this.findAddressMatch(this.addresses);
        this.form.vehicle = this.findVehicleMatch(this.vehicles);
        this.flowDirector.next(true);
      },
      props: {
        serviceGroup: this.form.serviceGroup!,
        user: this.userService.currentUser$.value!,
      },
    };
  }

  // completion

  loginPageDidComplete(phone) {
    this.form.phone = phone;
    this.flowDirector.next();
  }

  pinPageDidComplete(user: Nullable<User>) {
    this.form.user = user;
    this.loadingAlerts.showLoader();
    forkJoin([
      this.vehicleService.getVehicles(),
      this.userService.getCreditCards(),
      this.addressService.getUserAddresses(),
      this.orderService.getOrders(),
    ]).subscribe(async (results) => {
      this.addresses = results[2];
      this.vehicles = results[0];
      this.form.address = this.findAddressMatch(this.addresses);
      this.form.vehicle = this.findVehicleMatch(this.vehicles);
      await this.refreshUserHasNextOrder();
      this.loadingAlerts.dismissLoader();
      this.flowDirector.next();
    });
  }

  userLocationPageDidComplete(params: AddressParams) {
    this.addressService.createUserAddress(params).subscribe((userAddress) => {
      this.addressService.setSelectedAddress(userAddress).then((userAddress) => {
        this.addresses = [userAddress];
        this.didSelectAddress(userAddress.address);
      });
    });
  }

  didSelectAddress(address: Address) {
    this.form.address = address;
    this.getServices(address).subscribe(async (serviceGroup) => {
      if (serviceGroup) {
        this.form.serviceGroup = serviceGroup;
        await this.refreshUserHasNextOrder();
        this.flowDirector.next();
      } else {
        this.showNoServicePopOver();
      }
    });
  }

  addressesPageDidComplete(address: UserAddress) {
    this.didSelectAddress(address.address);
  }

  vehicleFlowDidComplete(vehicle: Vehicle) {
    this.vehicles = [vehicle];
    this.didSelectVehicle(vehicle);
  }

  vehiclesPageDidComplete(vehicle: Vehicle) {
    this.didSelectVehicle(vehicle);
  }

  async didSelectVehicle(vehicle: Vehicle) {
    this.form.vehicle = vehicle;
    await this.refreshUserHasNextOrder();
    this.flowDirector.next();
  }

  orderFlowDidComplete() {
    this.userHasOrder = true;
    this.flowDirector.next();
  }

  showNoServicePopOver() {
    this.modalService.open({
      component: SimpleMessageModalComponent,
      componentProps: {
        title: 'Currently Unavailable',
        description:
          'Unfortunately, that address is outside our service area at this time. Please try a different address.',
        actionButtonText: 'Search Again',
      },
    });
  }

  // data

  getServices(address: Address): Observable<Nullable<ServiceGroup>> {
    this.loadingAlerts.showLoader();
    return this.addressService.getServices(address, { includeFromGlobalRegion: true }).pipe(
      map((services) => {
        const filteredServices = this.filterServices(services);
        return ServiceGroup.groupServices(filteredServices)[0];
      }),
      finalize(() => {
        this.loadingAlerts.dismissLoader();
      })
    );
  }

  async refreshUserHasNextOrder() {
    if (this.form.serviceGroup && this.form.vehicle) {
      const serviceName = this.form.serviceGroup!.serviceType.name;
      const existingOrder = await this.orderSchedule.findNextOrderForService(
        this.form.vehicle,
        serviceName
      );
      this.userHasOrder = !!existingOrder;
    }
  }

  findAddressMatch(addresses: UserAddress[] = []): Nullable<Address> {
    return addresses.find((address) => {
      const nameMatch =
        address.address.locationName === this.props.variant.defaultLocation?.locationName;
      const zipMatch = address.address.zipCode === this.props.variant.defaultLocation?.zipCode;
      return nameMatch && zipMatch;
    })?.address;
  }

  findVehicleMatch(vehicles: Vehicle[] = []): Nullable<Vehicle> {
    return vehicles.find((vehicle) => {
      const modelMatch = vehicle.vehicleModel.uid === this.props.vehicleInfo?.vehicleModelUid;
      const yearMatch = vehicle.year === this.props.vehicleInfo?.year;
      return modelMatch && yearMatch;
    });
  }

  filterServices(services: Service[]): Service[] {
    return services.filter((service) =>
      service.matchesSearch(this.props.variant.serviceNameSearch)
    );
  }

  sendPin(phone: string) {
    this.userService.sendPin(phone).subscribe(
      () => {},
      (error) => {
        this.loadingAlerts.showToastAlert(error);
      }
    );
  }
}
