import { BaseEntity, Default, MemoizeGetters, Transform, toEntity } from './base';
import { toNumber, toString } from './base/transform';

import { FuelType } from './fuel-type';
import { ServiceName } from './service-type';

export class Make extends BaseEntity {
  uid: string;
  make: string;
}

export class Model extends BaseEntity {
  uid: string;
  model: string;
  useGas: boolean;
  useElectric: boolean;
}

@MemoizeGetters
export class Vehicle extends BaseEntity {
  static availableColors = [
    'Black',
    'White',
    'Blue',
    'Gray',
    'Red',
    'Purple',
    'Green',
    'Orange',
    'Brown',
    'Yellow',
    'Silver',
    'Bronze',
    'Gold',
  ];

  @Transform(toEntity(Make))
  vehicleMake: Make;

  @Transform(toEntity(Model))
  vehicleModel: Model;
  nickname?: string;

  @Transform(toString)
  year?: string;
  color?: string;
  vin?: string;
  license?: string;
  recommendedPsiFront?: number;
  recommendedPsiRear?: number;
  @Transform(toNumber)
  requestedPsiFront?: number;
  @Transform(toNumber)
  requestedPsiRear?: number;
  suggestedServices: ServiceName[];
  onstar?: boolean;
  fuelGaugeThreshold?: number;
  fuelLevel?: FuelLevel;
  toyotaConnect?: boolean;

  @Default('assets/images/vehicle-placeholder.svg')
  vehicleImageUrl: string;

  @Transform(toEntity(FuelType))
  fuelType?: FuelType;

  latestCompleteOrder?: OrderInfo;

  @Default([])
  photos: string[];

  // Computed Properties

  get lastOrderDate() {
    return this.latestCompleteOrder && new Date(this.latestCompleteOrder.date);
  }

  get fuelGrade() {
    return this.fuelType?.fuelGrade || 'Regular';
  }

  get oemConnected() {
    return this.onstar || this.toyotaConnect;
  }

  get isElectric(): boolean {
    return Boolean(this.fuelType?.fuelType === 'Electric');
  }

  get makeAndModel(): string | undefined {
    if (this.vehicleMake && this.vehicleModel) {
      return `${this.vehicleMake.make} ${this.vehicleModel.model}`;
    }
  }

  get description(): string {
    const yearString = this.year ? `${this.year} ` : '';
    const colorString = this.color ? `, ${this.color}` : '';
    return `${yearString}${this.makeAndModel}${colorString}`;
  }

  get hasCustomNickname() {
    return !this.nickname?.startsWith('Your');
  }

  get useGas(): boolean {
    return Boolean(this.vehicleModel?.useGas && !this.isElectric);
  }

  get useElectric(): boolean {
    return Boolean(this.vehicleModel?.useElectric || this.isElectric);
  }
}

export interface FuelLevel {
  level?: number;
  createdAt?: Date;
}

export enum VehicleSource {
  Uber = 'Uber',
  Yoshi = 'Yoshi',
}

export interface VehicleParams {
  vehicleModelUid?: string;
  fuelTypeUid?: string;
  color?: string;
  nickname?: string;
  license?: string;
  fuelGaugeThreshold?: number;
  year?: number;
  vin?: string;
  imageUrl?: string;
  requestedPsiFront?: number;
  requestedPsiRear?: number;
  vehicleSource?: VehicleSource;
  sourceIdentifier?: string;
}

export interface OrderInfo {
  date: string;
}
