import { Component, Input, OnInit } from '@angular/core';

import { AddressService } from 'src/app/services/api/address/address.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Day } from 'src/app/models/day';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Order } from 'src/app/models/order';
import { OrderService } from 'src/app/services/api/order/order.service';
import { Service } from 'src/app/models/service';
import { TimeSlot } from 'src/app/models/time-slot';
import { UserAddress } from 'src/app/models/user-address';

export interface TimeSlotSelectorPageProps {
  day: Day;
  service: Service;
  userAddress: UserAddress;
  isModal: boolean;
  selectedSlotStartTime?: string;
  currentOrder?: Order;
  onAddressChanged: (address: UserAddress) => void;
}

export interface TimeSlotSelectorPageForm {
  slot?: TimeSlot;
  availableSlots?: TimeSlot[];
}

@Component({
  selector: 'ysh-time-slot-selector',
  templateUrl: './time-slot-selector.page.html',
  styleUrls: ['./time-slot-selector.page.scss'],
})
export class TimeSlotSelectorPage implements OnInit {
  @Input() onComplete: (requestedSlot: TimeSlot) => void;
  @Input() onDismiss: () => void;

  props: TimeSlotSelectorPageProps;
  form: TimeSlotSelectorPageForm = {};

  validTimeSlot = true;

  constructor(
    private analytics: AnalyticsService,
    private orderService: OrderService,
    private modalService: ModalService,
    private loadingAlertCtrl: LoadingAlertService,
    private addressService: AddressService
  ) {}

  ngOnInit() {
    this.analytics.trackView('TimeSlotSelectorPage');
    if (this.props.day.slots.length) {
      this.form.availableSlots = this.props.day.slots;
      this.form.slot = this.getDefaultTime();
    } else {
      this.loadTimeslots();
    }
  }

  private getDefaultTime(): TimeSlot | undefined {
    const slots = this.form.availableSlots || [];
    const selectedSlotTime = this.props.selectedSlotStartTime;
    return this.props.selectedSlotStartTime
      ? slots.find((slot) => slot.startTimeString === selectedSlotTime)
      : slots.find((slot) => slot.available);
  }

  // Data

  loadTimeslots() {
    this.fetchTimeSlots().subscribe(
      (slots: TimeSlot[]) => {
        const currentDateString = this.props.day.date.toDateString();
        const dateFilter = (slot: TimeSlot) => slot.dateString === currentDateString;
        this.form.availableSlots = slots.filter(dateFilter);
        this.form.slot = this.getDefaultTime();
      },
      (error) => {
        this.loadingAlertCtrl.showToastAlert(`Error finding slots for selected Date: ${error}`);
      }
    );
  }

  private fetchTimeSlots() {
    return this.props.currentOrder
      ? this.orderService.getAvailableSlotsByOrderId(this.props.currentOrder!.uid)
      : this.addressService.getTimeSlots(
          this.props.userAddress,
          this.props.day.date,
          this.props.day.date,
          this.props.service
        );
  }

  // Actions

  didTapOnSlot(slot: TimeSlot) {
    this.form.slot = slot;
  }

  didSelectTimeSlot(requestedSlot: TimeSlot) {
    this.onComplete?.(requestedSlot);
  }

  didTapDismiss() {
    this.modalService.dismissModal();
  }

  didTapBack() {
    this.onDismiss?.();
  }

  didUpdateAddress(userAddress: UserAddress) {
    this.props.onAddressChanged?.(userAddress);
  }

  // Helpers

  isSelected(slot: TimeSlot) {
    return (
      slot.date.toDateString() === (this.form.slot || this.props.day).date.toDateString() &&
      slot.startTimeString === (this.form.slot?.startTimeString || this.props.selectedSlotStartTime)
    );
  }
}
