import { VirtualDefaultLocation, uberAreas } from './webflow-start.variants.areas';

import { FaqTag } from 'src/app/pages/services/components/faq/faq';
import { ServiceName } from 'src/app/models/service-type';

export interface WebflowVariant {
  serviceNameSearch: string[];
  inviteCode?: string;
  faqServiceName?: ServiceName;
  virtual: boolean;
  collectZipAtSignup?: boolean;
  collectAddressAtSignup?: boolean;
  defaultLocation?: VirtualDefaultLocation;
  faqTags?: FaqTag[];
  footerLegalText?: boolean;
  startButtonLabel?: string;
  mainImage?: string;
  mainHeading?: string;
  mainCaption?: string;
  mainList?: string[];
  mainMoreDetails?: string;
  mainFineprint?: string;
}

export const getWebflowVariant = (key: string, area?: any) => {
  const uberArea = uberAreas[area] || uberAreas.san_francisco;
  const webflowVariants: { [key: string]: WebflowVariant } = {
    uber: {
      serviceNameSearch: ['rideshare'],
      mainImage: './assets/images/webflow/webflow-start-inspection-uber-one.png',
      mainHeading: `Get a certified vehicle inspection at your home, <span>for only ${uberArea.servicePrice}</span>`,
      mainCaption:
        '<b>Uber and Yoshi</b> have partnered to help streamline vehicle inspections. Experience the benefits of Yoshi inspections at home:',
      mainList: [
        'Maximum convenience ',
        'Same day appointments in most cases',
        `${uberArea.serviceTimeWindow} availability, ${uberArea.serviceDaysWindow}`,
        'Reschedule or cancel anytime',
      ],
      faqServiceName: ServiceName.Inspection,
      faqTags: [FaqTag.UberMobile],
      virtual: false,
      footerLegalText: true,
    },
    uber_virtual: {
      serviceNameSearch: [uberArea.serviceName!],
      mainImage: './assets/images/webflow/webflow-start-inspection-uber-one.png',
      mainHeading: `Get a certified video chat vehicle inspection, <span>for only ${uberArea.servicePrice}</span>`,
      mainCaption: `<b>Uber and Yoshi</b> have partnered to help streamline vehicle inspections. Experience the benefits of Yoshi video chat inspections for ${uberArea.displayName} drivers:`,
      mainList: [
        'Maximum convenience',
        'Same day appointments in most cases',
        `${uberArea.serviceTimeWindow} availability, ${uberArea.serviceDaysWindow}`,
        'Reschedule or cancel anytime',
      ],
      startButtonLabel: 'Continue',
      faqServiceName: ServiceName.Inspection,
      faqTags: [FaqTag.UberVirtual],
      virtual: true,
      defaultLocation: uberArea.defaultLocation,
      footerLegalText: true,
    },
    turo_inspections: {
      serviceNameSearch: ['turo'],
      mainImage: './assets/images/webflow/webflow-start-inspection-turo-one.png',
      mainHeading:
        'Get a virtual or in-person vehicle inspection, <span>starting at just $25</span>',
      mainCaption:
        '<b>Yoshi, a trusted Turo partner,</b> provides fast, convenient, virtual or in-person vehicle inspections. Skip the long, stressful auto-shop experience and get your vehicles inspected by Yoshi, at the comfort of your home or lot.',
      mainList: [
        '15 minute video chat inspections for $25',
        'In-person inspections for only $40*',
        'Quick same day appointments available',
        'Appointments available 7 days a week',
        'Reschedule or cancel anytime',
      ],
      mainFineprint: '*In-person inspections are available in LA and the Bay Area.',
      virtual: true,
      faqServiceName: ServiceName.Inspection,
      faqTags: [FaqTag.Turo],
      defaultLocation: {
        locationName: 'Bismarck, ND',
        lat: 46.8166927,
        lng: -100.7787809,
        zipCode: '58505',
      },
      collectAddressAtSignup: true,
    },
    turo_virtual: {
      serviceNameSearch: ['turo', 'video'],
      mainImage: './assets/images/webflow/webflow-start-inspection-turo-two.png',
      mainHeading: 'Get a Turo-approved video chat vehicle inspection, <span>for only $25</span>',
      mainCaption:
        '<b>Yoshi and Turo</b> have partnered to help streamline vehicle inspections. Experience the benefits of Yoshi video chat inspections:',
      mainList: [
        'Approved for all US based Turo hosts',
        'Same day appointments in most cases',
        '10am-7pm CT availability, daily (except Sundays)',
        'Reschedule or cancel anytime',
      ],
      virtual: true,
      faqServiceName: ServiceName.Inspection,
      faqTags: [FaqTag.Turo],
      defaultLocation: {
        locationName: 'Bismarck, ND',
        lat: 46.8166927,
        lng: -100.7787809,
        zipCode: '58505',
      },
      collectZipAtSignup: true,
    },
    getaround_virtual: {
      serviceNameSearch: ['getaround'],
      mainImage: './assets/images/webflow/webflow-start-inspection-getaround-two.png',
      mainHeading: `Get a certified virtual vehicle inspection over video chat`,
      mainCaption: `<b>Getaround and Yoshi</b> have partnered to help streamline vehicle inspections. Experience the benefits of Yoshi video chat inspections:`,
      mainList: [
        'Maximum convenience',
        'Same day appointments in most cases',
        '10am-7pm CT availability, daily (except Sundays)',
        '$27.50 per video chat inspection',
      ],
      virtual: true,
      faqServiceName: ServiceName.Inspection,
      faqTags: [FaqTag.GetAround],
      defaultLocation: {
        locationName: 'Bismarck, ND',
        lat: 46.8166927,
        lng: -100.7787809,
        zipCode: '58505',
      },
    },
  };
  return webflowVariants[key];
};
