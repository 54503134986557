import { AddressParams, addressParamsForGooglePlaceData } from 'src/app/models/user-address';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar, Platform } from '@ionic/angular';
import { Observable, Subject, of } from 'rxjs';
import { UberEventsService, UberFlowEvent } from '../services/uber-events.service';
import { Vehicle, VehicleParams, VehicleSource } from 'src/app/models/vehicle';
import { WebflowVariant, getWebflowVariant } from './webflow-start.variants';
import { finalize, flatMap, map, tap } from 'rxjs/operators';

import { ActivatedRoute } from '@angular/router';
import { Address } from 'src/app/models/address';
import { AddressService } from 'src/app/services/api/address/address.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { Faq } from 'src/app/models/faq';
import { FaqsModalComponent } from 'src/app/components/faqs-modal/faqs-modal';
import { KeyboardService } from 'src/app/services/keyboard/keyboard.service';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { PlacesAutocompletePage } from 'src/app/pages/misc/places-autocomplete/places-autocomplete.page';
import { Service } from 'src/app/models/service';
import { ServiceGroup } from 'src/app/models/service-group';
import { ServiceName } from 'src/app/models/service-type';
import { SimpleMessageModalComponent } from 'src/app/components/simple-message-modal/simple-message-modal';
import { UberUserData } from 'src/app/models/uber-user-data';
import { UserService } from 'src/app/services/api/user/user.service';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';
import { WebflowLoginFlowControllerPage } from '../webflow-login-flow-controller/webflow-login-flow-controller.page';
import { WebflowSignupFlowControllerPage } from '../webflow-signup-flow-controller/webflow-signup-flow-controller.page';

@Component({
  selector: 'ysh-webflow-start-page',
  templateUrl: './webflow-start.page.html',
  styleUrls: ['./webflow-start.page.scss'],
})
export class WebflowStartPage implements OnInit, OnDestroy {
  @ViewChild('searchbarZip') searchbarZip: IonSearchbar;
  @ViewChild('searchbar') searchbar: IonSearchbar;

  googleAutocomplete: google.maps.places.Autocomplete;
  uberData: UberUserData;
  formData: AddressParams = {};
  vehicle: Nullable<Vehicle>;
  address: Nullable<Address>;
  serviceGroup: ServiceGroup;
  webflowVariant: WebflowVariant;
  mainCaption?: string | null;
  showAltLayout = false;
  featureFlagReady = false;
  layoutVisible = false;
  faq: Faq[] = [];
  zipCode: string;
  uberDataLoaded = false;

  constructor(
    public environment: EnvironmentService,
    public route: ActivatedRoute,
    public platform: Platform,
    public uberService: UberEventsService,
    private vehicleService: VehiclesService,
    private loadingAlerts: LoadingAlertService,
    private keyboardService: KeyboardService,
    private analytics: AnalyticsService,
    private userService: UserService,
    private modalService: ModalService,
    private addressService: AddressService
  ) {}

  // life cycle hooks

  async ngOnInit() {
    this.analytics.trackView('WebflowStartPage' + this.webflowVariant);
    this.getWebVariant();

    await this.userService.logout();
    this.loadData();
  }

  private getWebVariant() {
    this.webflowVariant = getWebflowVariant(
      this.route.snapshot.data['webflowVariant'],
      this.route.snapshot.data['webflowVariantArea']
    );
    if (this.webflowVariant.faqServiceName) {
      this.getFaqs(this.webflowVariant.faqServiceName);
    }
  }

  private loadData() {
    this.formData = this.webflowVariant.defaultLocation || this.formData;
    const shouldFindService = this.webflowVariant.virtual && this.webflowVariant.defaultLocation;
    const findServiceIfNeeded$ = shouldFindService
      ? this.findVirtualService(this.formData)
      : of(null);

    if (this.uberService.appIsEmbeddedInUber) {
      this.uberService.trackAnalyticsEvent(UberFlowEvent.UberFlowLoaded);
    }
    const getUberDataIfNeeded$ = this.uberService.appIsEmbeddedInUber
      ? this.uberService.getUberData$()
      : of(null);
    findServiceIfNeeded$
      .pipe(
        flatMap(() => getUberDataIfNeeded$),
        tap((data) => (this.uberData = data || this.uberData)),
        finalize(() => {
          this.uberDataLoaded = true;
        })
      )
      .subscribe(
        (data) => data && this.handleUberData(data),
        (error) => {
          console.error(error);
          this.uberService.trackAnalyticsEvent(UberFlowEvent.UberDataError, error);
        }
      );
  }

  private handleUberData(data: UberUserData) {
    this.uberData = data;
    this.uberService.trackAnalyticsEvent(UberFlowEvent.UberDataReceived);
    if (this.uberData.userHasAccount) {
      this.presentLoginFlow();
    }
  }

  private unsubscribe: Subject<void> = new Subject();

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  @HostListener('document:keyup.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // prevent ESC key in Uber
    if (this.uberService.appIsEmbeddedInUber) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  // actions

  async didTapSearch() {
    this.googleAutocomplete = new google.maps.places.Autocomplete(
      await this.searchbar.getInputElement()
    );
    this.googleAutocomplete.addListener('place_changed', () => {
      const data = this.googleAutocomplete.getPlace();
      this.createDataForGooglePlace(data);
    });
    google.maps.event.clearInstanceListeners(this.searchbar.getInputElement());
    document.querySelector('.pac-container')?.remove();
  }

  didTapSearchZip() {
    const geocoder = new google.maps.Geocoder();
    this.loadingAlerts.showLoader();
    geocoder.geocode({ address: String(this.zipCode) }, (results, status) => {
      this.loadingAlerts.dismissLoader();
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          this.clearLocationData();
          this.createDataForGooglePlace(results[0]);
          this.didTapDone();
        } else {
          this.loadingAlerts.presentAlert('Failed to get zip code', 'No results found');
        }
      } else {
        this.loadingAlerts.presentAlert(
          'Failed to get zip code',
          'Geocoder failed due to status ' + status
        );
      }
    });
  }

  didTapDone() {
    this.getServices(this.formData).subscribe((serviceGroup) => {
      if (serviceGroup) {
        this.serviceGroup = serviceGroup;
        this.didTapSchedule();
      } else {
        this.presentNoServicePopOver();
      }
    });
  }

  didTapLogin() {
    this.presentLoginFlow();
  }

  async didTapNewAddress() {
    const modal = await this.modalService.openPage({ component: PlacesAutocompletePage });
    this.keyboardService.close();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.clearLocationData();
      this.createDataForGooglePlace(data);
    }
    setTimeout(() => {
      this.keyboardService.close();
    }, 600);
  }

  didTapFAQS() {
    this.modalService.openPage({
      component: FaqsModalComponent,
      props: {
        faq: this.faq,
      },
    });
  }

  didTapSchedule() {
    if (this.uberData) {
      this.signupUserAndVehicle();
    } else {
      this.presentSignupFlow(true);
    }
  }

  // navigation

  presentLoginFlow() {
    this.modalService.openPage({
      component: WebflowLoginFlowControllerPage,
      componentProps: {
        onDismiss: () => this.modalService.dismissModal(),
      },
      props: {
        variant: this.webflowVariant,
        phone: this.uberData?.phone,
        service: this.serviceGroup?.services[0],
        serviceGroup: this.serviceGroup,
        vehicleInfo: {
          vehicleModelUid: this.uberData?.vehicleModelUid,
          year: this.uberData?.vehicleYear,
        },
      },
    });
  }

  presentSignupFlow(showServiceDetail: boolean) {
    const initialVehicleParams = this.getUberVehicleParams();
    this.modalService.openPage({
      component: WebflowSignupFlowControllerPage,
      componentProps: {
        onDismiss: () => this.modalService.dismissModal(),
      },
      props: {
        user: this.userService.currentUser$.value,
        serviceGroup: this.serviceGroup,
        service: showServiceDetail ? null : this.serviceGroup.services[0],
        vehicle: this.vehicle,
        address: this.address!,
        addressParams: this.formData,
        webflowVariant: this.webflowVariant,
        initialVehicleParams,
        didRequestLogin: () => {
          this.modalService.dismissModal();
          this.presentLoginFlow();
        },
      },
    });
  }

  private getUberVehicleParams() {
    const shouldPassUberUid = !this.vehicle && this.uberData?.uberVehicleUuid;
    return shouldPassUberUid
      ? {
          sourceIdentifier: this.uberData.uberVehicleUuid,
          vehicleSource: VehicleSource.Uber,
        }
      : undefined;
  }

  presentNoServicePopOver() {
    const description = this.webflowVariant.virtual
      ? 'Unfortunately, we are not performing virtual inspection in this region.'
      : 'Unfortunately, that address is outside our service area at this time. Please try a different address.';
    this.modalService.open({
      component: SimpleMessageModalComponent,
      componentProps: {
        title: 'Currently Unavailable',
        description,
        actionButtonText: 'Search Again',
      },
    });
  }

  // data

  loadingVirtualServices = false;

  findVirtualService(addressParams: AddressParams) {
    this.loadingVirtualServices = true;
    return this.getServices(addressParams, false).pipe(
      finalize(() => {
        this.loadingVirtualServices = false;
      }),
      tap((serviceGroup) => {
        if (serviceGroup) {
          this.serviceGroup = serviceGroup;
        }
      })
    );
  }

  createDataForGooglePlace(data) {
    const addressParams = addressParamsForGooglePlaceData(data);
    if (addressParams.zipCode) {
      this.formData = {
        ...this.formData,
        ...addressParams,
      };
    } else {
      this.loadingAlerts.showToastAlert('Please pick a more specific address.');
    }
  }

  getServices(params: AddressParams, showSpinner = true): Observable<Nullable<ServiceGroup>> {
    if (showSpinner) {
      this.loadingAlerts.showLoader();
    }
    return this.addressService.createAddress(params).pipe(
      flatMap((address) => {
        this.address = address;
        return this.addressService.getServices(address, { includeFromGlobalRegion: true });
      }),
      map((services) => {
        const filteredServices = this.filterServices(services);
        return ServiceGroup.groupServices(filteredServices)[0];
      }),
      finalize(() => {
        if (showSpinner) {
          this.loadingAlerts.dismissLoader();
        }
      })
    );
  }

  getFaqs(name: ServiceName) {
    this.addressService
      .getFaqs(name, this.webflowVariant.faqTags)
      .subscribe((faq) => (this.faq = faq));
  }

  filterServices(services: Service[]): Service[] {
    return services.filter((service) =>
      service.matchesSearch(this.webflowVariant.serviceNameSearch)
    );
  }

  clearLocationData() {
    this.formData = {};
    this.address = null;
  }

  addressValidates() {
    return (
      this.formData?.locationName &&
      this.formData?.lat &&
      this.formData?.lng &&
      this.formData?.zipCode
    );
  }

  async signupUserAndVehicle() {
    this.loadingAlerts.showLoader();

    // TODO: Replace phone format hack when api is updated
    this.userService
      .signup({ ...this.uberData, ...this.formData, phone: this.uberData.phone?.replace('+1', '') })
      .pipe(
        flatMap(() => (this.uberData.validVehicleInfo ? this.createVehicle() : of(null))),
        flatMap((vehicle?: Vehicle) => {
          this.vehicle = vehicle;
          return this.addressService.getUserAddresses();
        }),
        finalize(() => {
          this.loadingAlerts.dismissLoader();
        })
      )
      .subscribe(
        () => {
          this.presentSignupFlow(false);
        },
        (error) => {
          this.loadingAlerts.presentAlert('Error creating account', error);
        }
      );
  }

  private createVehicle() {
    return this.vehicleService.createVehicle({
      vehicleModelUid: this.uberData.vehicleModelUid,
      color: this.uberData.vehicleColorName,
      year: this.uberData.vehicleYear,
      vehicleSource: VehicleSource.Uber,
      sourceIdentifier: this.uberData?.uberVehicleUuid,
    });
  }
}
