import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FeatureFlagService, Flag } from 'src/app/services/feature-flag/feature-flag.service';
import { ModalService, YshModals } from 'src/app/services/modal/modal.service';
import {
  PaymentRequest,
  PaymentRequestWallet,
  StripeElements,
  StripePaymentRequestButtonElement,
} from '@stripe/stripe-js';

import { ApplePayService } from 'src/app/services/apple-pay/apple-pay.service';
import { CreditCardFlowPage } from 'src/app/pages/flows/payment/credit-card-flow/credit-card-flow.page';
import { Platform } from '@ionic/angular';
import { Service } from 'src/app/models/service';
import { StripeService } from 'src/app/services/stripe/stripe';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/services/api/user/user.service';
import { takeUntil } from 'rxjs/operators';

export interface YshPaymentSheetProps {
  outlineInputStyle?: boolean;
  service?: Nullable<Service>;
}

@Component({
  selector: 'ysh-payment-sheet',
  templateUrl: './ysh-payment-sheet.component.html',
  styleUrls: ['./ysh-payment-sheet.component.scss'],
})
export class YshPaymentSheetComponent implements OnInit, OnDestroy {
  static PAGE_NAME = YshModals.YshPaymentSheetComponent;
  @Input() onComplete: (data: any) => void;
  @Input() props: YshPaymentSheetProps;

  elements?: StripeElements;
  title = 'Choose a payment method';
  canSkipPayment = false;
  applePayAllowed = false;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private featureFlags: FeatureFlagService,
    protected platform: Platform,
    private applePayService: ApplePayService,
    private modalService: ModalService,
    private stripeService: StripeService,
    private userService: UserService
  ) {
    this.featureFlags
      .flag$(Flag.AllowPaymentSkip)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((flag) => {
        this.canSkipPayment = flag;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit() {
    this.configurePaymentRequestButton();
  }

  async configurePaymentRequestButton() {
    this.elements = this.stripeService.instance?.elements({ locale: 'auto' });
    if (!this.elements) {
      throw 'Stripe failed to initialize';
    }
    const paymentRequest = this.createStripePaymentRequest();
    if (!(await paymentRequest.canMakePayment())) {
      return;
    }
    this.createStripePaymentRequestButton(paymentRequest);
    this.listenForPaymentRequestToken(paymentRequest);
  }

  private createStripePaymentRequest(): PaymentRequest {
    return this.stripeService.instance!.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Yoshi, Inc',
        amount: 100,
        pending: true,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      disableWallets: ['link' as PaymentRequestWallet],
    });
  }

  private createStripePaymentRequestButton(paymentRequest: PaymentRequest) {
    const button = this.elements!.create('paymentRequestButton', {
      paymentRequest,
      style: {
        paymentRequestButton: {
          height: '50px',
        },
      },
    });
    button.mount('#payment-request-button');
    return button;
  }

  private listenForPaymentRequestToken(paymentRequest: PaymentRequest) {
    paymentRequest.on('token', async (ev) => {
      this.userService.createCreditCard({ token: ev.token!.id }).subscribe(
        () => {
          ev.complete('success');
          this.didFinish(true);
        },
        (error) => {
          ev.complete('fail');
        }
      );
    });
  }

  // Actions

  async didTapCreditCard() {
    const modal = await this.modalService.openPage({
      component: CreditCardFlowPage,
      componentProps: {
        hideBackButton: true,
        showInDarkTheme: true,
        showDismissBtn: true,
        outlineInputStyle: this.props.outlineInputStyle,
        service: this.props.service,
      },
    });
    const { data } = await modal.onDidDismiss();
    setTimeout(() => this.didFinish(data), 50); // nested modal requires a timeout to dismiss properly
  }

  async didTapApplePay() {
    const paymentSuccess = await this.applePayService.requestPaymentInfo();
    this.didFinish(paymentSuccess);
  }

  didTapLater() {
    this.didFinish(true);
  }

  onDismiss() {
    this.didFinish(false);
  }

  didFinish(done: boolean) {
    this.modalService.dismissModal(done);
  }
}
